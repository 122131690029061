/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.text-brand{
    color: #96AC78!important;
  }
  .text-brand-secondary{
    color: #e5e8e1!important;
  }
  
  .bg-brand{
    background: #E8A143;
  }
  
  .btn-warning {
    color: white;
    background-color: #E8A143;
    border-color: #E8A143;
  }
  
  .btn-warning:focus {
    color: white!important;
    background-color: #E8A143!important;
    border-color: #E8A143!important;
  }
  
  .btn-warning:hover {
    background-color: #dfaf5e!important;
    border-color: #dfaf5e!important;
  }
  
  .btn-warning:focus {
    background-color: #dfaf5e!important;
    border-color: #dfaf5e!important;
  }
  
  .btn-success {
    color: #2d2c2c!important;
    background-color: #F0C48A!important;
    border-color: #F0C48A!important;
  }
  
  .btn-success:focus {
    color: #2d2c2c!important;
    box-shadow: 0 0 0 0.2rem #E8A143 50%!important;
    background-color: #E8A143!important;
    border-color: #E8A143!important;
  }
  
  .btn-success:hover {
    color: #2d2c2c!important;
    background-color: #E8A143!important;
    border-color: #E8A143!important;
  }